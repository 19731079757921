<template>
  <div v-if="formData && formData.configuracaoEsocial.strValor">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Integração do E-Social</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            label="Ambiente"
            name="intTipoAmbiente"
            v-model="
              formData.configuracaoEsocial.strValor.intTipoAmbiente
            "
            :items="bind.arrayTipoAmbiente"
            rules="required"
          />
          
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            label="Versão do Processo"
            name="strVersaoProcesso"
            v-model="
              formData.configuracaoEsocial.strValor.strVersaoProcesso
            "
            :items="bind.arrayVersaoProcesso"
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            label="Versão do Layout"
            name="strVersaoLayout"
            v-model="
              formData.configuracaoEsocial.strValor.strVersaoLayout
            "
            :items="bind.arrayVersaoLayout"
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6mt-0 pt-0"
            label="Versão do Webservice"
            name="strVersaoWebservice"
            v-model="
              formData.configuracaoEsocial.strValor.strVersaoWebservice
            "
            :items="bind.arrayVersaoWebservice"
            rules="required"
          />
        </div>

        <span class="form-title">Enviar Lote por Email</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            label="Habilitar Envio de Email ao Gerar Lote"
            name="boolHabilitarEnvioEmailLote"
            v-model="
              formData.configuracaoEsocial.strValor.boolHabilitarEnvioEmailLote
            "
            info="Se habilitar essa opção o sistema vai enviar automaticamente um email para a empresa ao gerar um novo lote"
            :items="bind.arrayHabilitar"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.configuracaoEsocial.strValor.arrayEmpresaEmail"
            label="Enviar para qual Empresa?"
            name="arrayEmpresaEmail"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            placeholder="Todas"
            :filters="{ intTipoCadastroGeralId: 58,strBusca:(formData.configuracaoEsocial.strValor.arrayEmpresaEmail.length ? formData.configuracaoEsocial.strValor.arrayEmpresaEmail : null) }"
            :disabled="!formData.configuracaoEsocial.strValor.boolHabilitarEnvioEmailLote ? true : false"
            :initializeItems="formData.configuracaoEsocial.strValor.arrayEmpresaEmail.length  ? true : false"
            clearable
            multiple
          />
        </div>

        <span class="form-title">Transmitir Lote para o E-Social</span>

        <div class="row">
          <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            label="Habilitar Transmissão Automática do Lote"
            name="boolHabilitarTransmissaoAutomatica"
            v-model="
              formData.configuracaoEsocial.strValor.boolHabilitarTransmissaoAutomatica
            "
            info="Se habilitar essa opção o sistema vai transmitir automaticamente o lote no portal do eSocial"
            :items="bind.arrayHabilitar"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao"
            label="Enviar para qual Empresa?"
            name="arrayEmpresaTransmissao"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            placeholder="Todas"
            :filters="{ intTipoCadastroGeralId: 58,strBusca:(formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao.length ? formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao : null) }"
            :disabled="!formData.configuracaoEsocial.strValor.boolHabilitarTransmissaoAutomatica ? true : false"
            :initializeItems="formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao.length  ? true : false"
            clearable
            multiple
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSelect,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    EdInputAutoComplete,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
