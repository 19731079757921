var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formData && _vm.formData.configuracaoEsocial.strValor)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left"},[_c('span',{staticClass:"form-title"},[_vm._v("Integração do E-Social")]),_c('div',{staticClass:"row"},[_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0",attrs:{"label":"Ambiente","name":"intTipoAmbiente","items":_vm.bind.arrayTipoAmbiente,"rules":"required"},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.intTipoAmbiente
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "intTipoAmbiente", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.intTipoAmbiente\n          "}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0",attrs:{"label":"Versão do Processo","name":"strVersaoProcesso","items":_vm.bind.arrayVersaoProcesso,"rules":"required"},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.strVersaoProcesso
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "strVersaoProcesso", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.strVersaoProcesso\n          "}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0",attrs:{"label":"Versão do Layout","name":"strVersaoLayout","items":_vm.bind.arrayVersaoLayout,"rules":"required"},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.strVersaoLayout
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "strVersaoLayout", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.strVersaoLayout\n          "}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6mt-0 pt-0",attrs:{"label":"Versão do Webservice","name":"strVersaoWebservice","items":_vm.bind.arrayVersaoWebservice,"rules":"required"},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.strVersaoWebservice
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "strVersaoWebservice", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.strVersaoWebservice\n          "}})],1),_c('span',{staticClass:"form-title"},[_vm._v("Enviar Lote por Email")]),_c('div',{staticClass:"row"},[_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0",attrs:{"label":"Habilitar Envio de Email ao Gerar Lote","name":"boolHabilitarEnvioEmailLote","info":"Se habilitar essa opção o sistema vai enviar automaticamente um email para a empresa ao gerar um novo lote","items":_vm.bind.arrayHabilitar},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.boolHabilitarEnvioEmailLote
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "boolHabilitarEnvioEmailLote", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.boolHabilitarEnvioEmailLote\n          "}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0",attrs:{"label":"Enviar para qual Empresa?","name":"arrayEmpresaEmail","item-text":"strNome","route":"Cadastro/CadastroGeral","placeholder":"Todas","filters":{ intTipoCadastroGeralId: 58,strBusca:(_vm.formData.configuracaoEsocial.strValor.arrayEmpresaEmail.length ? _vm.formData.configuracaoEsocial.strValor.arrayEmpresaEmail : null) },"disabled":!_vm.formData.configuracaoEsocial.strValor.boolHabilitarEnvioEmailLote ? true : false,"initializeItems":_vm.formData.configuracaoEsocial.strValor.arrayEmpresaEmail.length  ? true : false,"clearable":"","multiple":""},model:{value:(_vm.formData.configuracaoEsocial.strValor.arrayEmpresaEmail),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "arrayEmpresaEmail", $$v)},expression:"formData.configuracaoEsocial.strValor.arrayEmpresaEmail"}})],1),_c('span',{staticClass:"form-title"},[_vm._v("Transmitir Lote para o E-Social")]),_c('div',{staticClass:"row"},[_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0",attrs:{"label":"Habilitar Transmissão Automática do Lote","name":"boolHabilitarTransmissaoAutomatica","info":"Se habilitar essa opção o sistema vai transmitir automaticamente o lote no portal do eSocial","items":_vm.bind.arrayHabilitar},model:{value:(
            _vm.formData.configuracaoEsocial.strValor.boolHabilitarTransmissaoAutomatica
          ),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "boolHabilitarTransmissaoAutomatica", $$v)},expression:"\n            formData.configuracaoEsocial.strValor.boolHabilitarTransmissaoAutomatica\n          "}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0",attrs:{"label":"Enviar para qual Empresa?","name":"arrayEmpresaTransmissao","item-text":"strNome","route":"Cadastro/CadastroGeral","placeholder":"Todas","filters":{ intTipoCadastroGeralId: 58,strBusca:(_vm.formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao.length ? _vm.formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao : null) },"disabled":!_vm.formData.configuracaoEsocial.strValor.boolHabilitarTransmissaoAutomatica ? true : false,"initializeItems":_vm.formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao.length  ? true : false,"clearable":"","multiple":""},model:{value:(_vm.formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao),callback:function ($$v) {_vm.$set(_vm.formData.configuracaoEsocial.strValor, "arrayEmpresaTransmissao", $$v)},expression:"formData.configuracaoEsocial.strValor.arrayEmpresaTransmissao"}})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }